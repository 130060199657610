// store2.js
import { configureStore } from '@reduxjs/toolkit';
import counterSlice from './reducers/counterSlice';

const storeReduxToolKit = configureStore({
  reducer: {
    counter: counterSlice,
    // Add other reducers for store2
  },
});

export default storeReduxToolKit;
