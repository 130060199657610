import React from 'react';
import css from './InformationPagesMenu.module.css';
import InformationPagesIcon from './InformationPagesIcon';
import { FormattedMessage } from '../../../util/reactIntl';
import Menu from '../../Menu/Menu';
import MenuLabel from '../../MenuLabel/MenuLabel';
import MenuContent from '../../MenuContent/MenuContent';
import MenuItem from '../../MenuItem/MenuItem';
import NamedLink from '../../NamedLink/NamedLink';

const InformationPagesMenu = (props) => (
  <Menu>
    <MenuLabel className={css.languageMenuLabel}>
      <InformationPagesIcon />
    </MenuLabel>
    <MenuContent className={css.languageMenuContent}>
      <MenuItem key="aboutUsPage" className={css.languageMenuItem}>
        <div>
          <NamedLink name="AboutUsPage">
            <FormattedMessage id="Footer.aboutUs" />
          </NamedLink>
        </div>
      </MenuItem>
      <MenuItem key="blogItem" className={css.languageMenuItem}>
        <div>
            <FormattedMessage id="Footer.supportCenter" />
        </div>
      </MenuItem>
      <MenuItem key="blogPage" className={css.languageMenuItem}>
        <div>
          <NamedLink name="BlogPage">
            <FormattedMessage id="Footer.blog" />
          </NamedLink>
        </div>
      </MenuItem>
      <MenuItem key="faqs" className={css.languageMenuItem}>
        <div>
          <NamedLink name="FAQsPage"> 
            <FormattedMessage id="Footer.faqs" />
          </NamedLink>  
        </div>
      </MenuItem>
    </MenuContent>
  </Menu>
);

export default InformationPagesMenu;
