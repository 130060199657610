import React, { useEffect } from 'react';
import { any, string } from 'prop-types';
import ReactDOMServer from 'react-dom/server';

// react-dates needs to be initialized before using any react-dates component
// https://github.com/airbnb/react-dates#initialize
// NOTE: Initializing it here will initialize it also for app.test.js
import 'react-dates/initialize';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, StaticRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import difference from 'lodash/difference';
import mapValues from 'lodash/mapValues';

// Configs and store setup
import defaultConfig from './config/configDefault';
import appSettings from './config/settings';
import configureStore from './store';

// utils
import { RouteConfigurationProvider } from './context/routeConfigurationContext';
import { ConfigurationProvider } from './context/configurationContext';
import { mergeConfig } from './util/configHelpers';
import { IntlProvider } from './util/reactIntl';
import { IncludeScripts } from './util/includeScripts';

import { MaintenanceMode } from './components';

// routing
import routeConfiguration from './routing/routeConfiguration';
import Routes from './routing/Routes';

import {
  defaultLocale,
  defaultMessages,
  getPreferredLanguage,
  locales,
  messagesInLocale,
} from './translations/translations';
import NotFoundPage from './containers/NotFoundPage/NotFoundPage';

const loadScript = (src, onLoad) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = src;
  script.async = true;
  script.onload = onLoad;
  document.head.appendChild(script);
};

// If translation key is missing from `messagesInLocale` (e.g. fr.json),
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
const addMissingTranslations = (sourceLangTranslations, targetLangTranslations) => {
  const sourceKeys = Object.keys(sourceLangTranslations);
  const targetKeys = Object.keys(targetLangTranslations);

  // if there's no translations defined for target language, return source translations
  if (targetKeys.length === 0) {
    return sourceLangTranslations;
  }
  const missingKeys = difference(sourceKeys, targetKeys);

  const addMissingTranslation = (translations, missingKey) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey],
  });

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
};

// Get default messages for a given locale.
//
// Note: Locale should not affect the tests. We ensure this by providing
//       messages with the key as the value of each message and discard the value.
//       { 'My.translationKey1': 'My.translationKey1', 'My.translationKey2': 'My.translationKey2' }
const isTestEnv = process.env.NODE_ENV === 'test';
const localeMessages = (locale = 'en') => {
  return isTestEnv
    ? mapValues(defaultMessages, (val, key) => key)
    : addMissingTranslations(messagesInLocale[defaultLocale], messagesInLocale[locale]);
};

const Configurations = props => {
  const { appConfig, children } = props;
  const routeConfig = routeConfiguration(appConfig.layout);

  return (
    <ConfigurationProvider value={appConfig}>
      <RouteConfigurationProvider value={routeConfig}>{children}</RouteConfigurationProvider>
    </ConfigurationProvider>
  );
};

const MaintenanceModeError = props => {
  const { locale, messages, helmetContext } = props;
  return (
    <IntlProvider locale={locale} messages={messages} textComponent="span">
      <HelmetProvider context={helmetContext}>
        <MaintenanceMode />
      </HelmetProvider>
    </IntlProvider>
  );
};
import { ApolloProvider, InMemoryCache, ApolloClient } from '@apollo/client';
import storeReduxToolKit from './redux/storeReduxToolKit';
import { apiBaseUrl } from './util/api';
import WhatsAppButton from './components/Button/WhatsAppButton';

export const ClientApp = props => {
  const client = new ApolloClient({
    uri: `${apiBaseUrl()}/graphql`,
    cache: new InMemoryCache(),
  });
  const { store, hostedTranslations = {}, hostedConfig = {}, userPreferedLanguage } = props;
  const appConfig = mergeConfig(hostedConfig, defaultConfig);

  // Show MaintenanceMode if the mandatory configurations are not available
  if (!appConfig.hasMandatoryConfigurations) {
    return (
      <MaintenanceModeError
        locale={preferedLanguage}
        messages={{ ...localeMessages(preferedLanguage), ...hostedTranslations }}
      />
    );
  }

  // Marketplace color and branding image comes from configs
  // If set, we need to create CSS Property and set it to DOM (documentElement is selected here)
  // This provides marketplace color for everything under <html> tag (including modals/portals)
  // Note: This is also set on Page component to provide server-side rendering.
  const elem = window.document.documentElement;
  if (appConfig.branding.marketplaceColor) {
    elem.style.setProperty('--marketplaceColor', appConfig.branding.marketplaceColor);
    elem.style.setProperty('--marketplaceColorDark', appConfig.branding.marketplaceColorDark);
    elem.style.setProperty('--marketplaceColorLight', appConfig.branding.marketplaceColorLight);
  }
  // This gives good input for debugging issues on live environments, but with test it's not needed.
  const logLoadDataCalls = appSettings?.env !== 'test';

  const getLanguageFromNavigator = () => {
    const userLanguage = navigator.language.split('-')[0];
    return locales.includes(userLanguage) ? userLanguage : defaultLocale;
  };

  const getLanguageFromPath = () => {
    const pathSegments = window.location.pathname.split('/');
    const languageSegment = pathSegments[1]; // Primer segmento de la ruta
    return locales.includes(languageSegment) ? languageSegment : getLanguageFromNavigator();
  };

  const REACT_APP_ALOKA_ENV = process.env.REACT_APP_ALOKA_ENV;
  useEffect(() => {
    if (REACT_APP_ALOKA_ENV === 'production')
      loadScript('https://tracker.metricool.com/resources/be.js', () => {
        // Script loaded callback
        if (window.beTracker) {
          window.beTracker.t({ hash: '364fd845e51411c8a714ecd1bf59d055' });
        }
      });
  }, []);
  return (
    <Configurations appConfig={appConfig}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Switch>
            <Route path="/:lang([a-z]{2})">
              {({ match, location }) => {
                const params = match ? match.params : {};
                const { lang } = params;
                const { pathname, search } = location;

                if (!locales.includes(lang)) {
                  const toLang = userPreferedLanguage || getPreferredLanguage(navigator);
                  return <Redirect exact to={`${pathname.replace(lang, toLang)}${search}`} />;
                }

                return (
                  <IntlProvider
                    locale={lang}
                    messages={{ ...hostedTranslations, ...localeMessages(lang) }}
                    textComponent="span"
                  >
                    <Provider store={store}>
                      <HelmetProvider>
                        <Helmet>
                          <link rel="manifest" href={`/${lang}/site.webmanifest`} />
                        </Helmet>
                        <IncludeScripts config={appConfig} />
                        <Routes logLoadDataCalls={logLoadDataCalls} />
                      </HelmetProvider>
                    </Provider>
                  </IntlProvider>
                );
              }}
            </Route>
            <Route>
              {({ location }) => {
                const { pathname, search } = location;
                const toLang = userPreferedLanguage || getPreferredLanguage(navigator);
                return <Redirect exact to={`/${toLang}${pathname}${search}`} />;
              }}
            </Route>
          </Switch>
          <WhatsAppButton />
        </BrowserRouter>
      </ApolloProvider>
    </Configurations>
  );
};

ClientApp.propTypes = { store: any.isRequired };

export const ServerApp = props => {
  const REACT_APP_ALOKA_ENV = process.env.REACT_APP_ALOKA_ENV;
  console.log('server app', REACT_APP_ALOKA_ENV);
  useEffect(() => {
    if (REACT_APP_ALOKA_ENV === 'production')
      loadScript('https://tracker.metricool.com/resources/be.js', () => {
        // Script loaded callback
        if (window.beTracker) {
          window.beTracker.t({ hash: '364fd845e51411c8a714ecd1bf59d055' });
        }
      });
  }, []);
  const { url, context, helmetContext, store, hostedTranslations = {}, hostedConfig = {} } = props;
  const appConfig = mergeConfig(hostedConfig, defaultConfig);
  HelmetProvider.canUseDOM = false;

  // Show MaintenanceMode if the mandatory configurations are not available
  if (!appConfig.hasMandatoryConfigurations) {
    return (
      <MaintenanceModeError
        locale={'en'}
        messages={{ ...localeMessages('en'), ...hostedTranslations }}
        helmetContext={helmetContext}
      />
    );
  }

  return (
    <Configurations appConfig={appConfig}>
      <StaticRouter location={url} context={context}>
        <Switch>
          <Route path="/:lang([a-z]{2})">
            {({ match, location }) => {
              const params = match ? match.params : {};
              const { lang = defaultLocale } = params;

              const { pathname, search } = location;

              if (!locales.includes(lang)) {
                return <Redirect exact to={`${pathname.replace(lang, defaultLocale)}${search}`} />;
              }

              return (
                <IntlProvider
                  locale={lang}
                  messages={{ ...hostedTranslations, ...localeMessages(lang) }}
                  textComponent="span"
                >
                  <Provider store={store}>
                    <HelmetProvider context={helmetContext}>
                      <Helmet>
                        <link rel="manifest" href={`/${lang}/site.webmanifest`} />
                      </Helmet>
                      <IncludeScripts config={appConfig} />
                      <Routes />
                    </HelmetProvider>
                  </Provider>
                </IntlProvider>
              );
            }}
          </Route>
        </Switch>
      </StaticRouter>
    </Configurations>
  );
};

ServerApp.propTypes = { url: string.isRequired, context: any.isRequired, store: any.isRequired };

/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (
  url,
  serverContext,
  preloadedState,
  hostedTranslations,
  hostedConfig,
  collectChunks
) => {
  // Don't pass an SDK instance since we're only rendering the
  // component tree with the preloaded store state and components
  // shouldn't do any SDK calls in the (server) rendering lifecycle.
  const store = configureStore(preloadedState);
  const helmetContext = {};

  // When rendering the app on server, we wrap the app with webExtractor.collectChunks
  // This is needed to figure out correct chunks/scripts to be included to server-rendered page.
  // https://loadable-conksmponents.com/docs/server-side-rendering/#3-setup-chunkextractor-server-side
  const WithChunks = collectChunks(
    <ServerApp
      url={url}
      context={serverContext}
      helmetContext={helmetContext}
      store={store}
      hostedTranslations={hostedTranslations}
      hostedConfig={hostedConfig}
    />
  );

  const body = ReactDOMServer.renderToString(WithChunks);
  const { helmet: head } = helmetContext;
  return { head, body };
};
