// AccordionContainer.js
import React, { useState } from 'react';
// import data from './data.json';
import AccordionItem from './AccordionItem';
import './Accordion.css';

const AccordionContainer = ({ data }) => {
  const [openIndex, setOpenIndex] = useState('');

  const handleToggle = title => {
    setOpenIndex(title === openIndex ? null : title);
    // const newIndex = data.find(el => el.title === title);
    // setOpenIndex(title);
  };
  function divideArray() {
    const length = data.length;
    const firstLength = Math.ceil(length / 2); // Calculate the length of the first part

    const firstArray = data.slice(0, firstLength);
    const secondArray = data.slice(firstLength, data.length);

    return { firstArray, secondArray };
  }
  const newdivideArray = divideArray();
  return (
    <div className="main_accordion_container">
      <div className="AccordionContainer">
        {newdivideArray.firstArray.map((item, index) => (
          <AccordionItem
            key={index}
            title={item.title}
            content={item.content}
            isOpen={item.title === openIndex}
            onClick={() => handleToggle(item.title)}
          />
        ))}
      </div>
      <div className="AccordionContainer">
        {newdivideArray.secondArray.map((item, index) => (
          <AccordionItem
            key={index}
            index={index + 1}
            title={item.title}
            content={item.content}
            isOpen={item.title === openIndex}
            onClick={() => handleToggle(item.title)}
          />
        ))}
      </div>
    </div>
  );
};

export default AccordionContainer;
