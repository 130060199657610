import React, { useState, Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl';
import { FieldDateRangeController, Form, LocationAutocompleteInput, Button, OutsideClickHandler } from '../../../components';

import IconSearchDesktop from './IconSearchDesktop';
import css from './TopbarSearchForm.module.css';

import IconCalendar from './IconCalendar';
import { stringifyDateToISO8601 } from '../../../util/dates';

const identity = v => v;

const KeywordSearchField = props => {
  const { keywordSearchWrapperClasses, iconClass, intl, isMobile, inputRef } = props;
  return (
    <div className={keywordSearchWrapperClasses}>
      <div className={iconClass}>
        <IconSearchDesktop />
      </div>
      <Field
        name="keywords"
        render={({ input, meta }) => {
          return (
            <input
              className={isMobile ? css.mobileInput : css.desktopInput}
              {...input}
              id={isMobile ? 'keyword-search-mobile' : 'keyword-search'}
              data-testid={isMobile ? 'keyword-search-mobile' : 'keyword-search'}
              ref={inputRef}
              type="text"
              placeholder={intl.formatMessage({
                id: 'TopbarSearchForm.placeholder.keywords',
              })}
              autoComplete="off"
            />
          );
        }}
      />
    </div>
  );
};

const LocationSearchField = props => {
  const { desktopInputRootClass, intl, isMobile, inputRef, onLocationChange } = props;
  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        const searchOnChange = value => {
          onChange(value);

          if (value.selectedPlace) {
            onLocationChange(value);
          }
        };

        return (
          <LocationAutocompleteInput
            className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
            iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
            inputClassName={isMobile ? css.mobileInput : css.desktopInput}
            predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
            predictionsAttributionClassName={isMobile ? css.mobilePredictionsAttribution : null}
            placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder.location' })}
            closeOnBlur={!isMobile}
            inputRef={inputRef}
            // input={input}
            input={{
              ...restInput, onChange: searchOnChange
            }}
            meta={meta}
          />
        );
      }}
    />
  );
};

const DateRangeSearchField = props => {
  const { datesSearchWrapperClasses, iconClass, isMobile, intl, inputRef, form } = props;

  const [value, setValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const frontendFormat = {
    month: 'short',
    day: 'numeric',
  };

  const urlFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const _buildFrontendDates = (newStartDate, newEndDate) => {
    return `${intl.formatDate(newStartDate, frontendFormat)} - ${intl.formatDate(newEndDate, frontendFormat)}`;
  };

  const _buildFrontendDatesFromString = (datesString) => {
    if (datesString == undefined || datesString == null || datesString.length == 0) return '';
    const dates = datesString.split(",");
    const startDate = new Date(dates[0]);
    const endDate = new Date(dates[1]);
    return `${intl.formatDate(startDate, frontendFormat)} - ${intl.formatDate(endDate, frontendFormat)}`;
  };

  const _buildUrlDates = (newStartDate, newEndDate) => {
    //Set url dates value in (Y-M-D,Y-M-D) format
    let start = stringifyDateToISO8601(newStartDate);
    let end = stringifyDateToISO8601(newEndDate);;
    return `${start},${end}`;
  };

  const handleChange = (newStartDate, newEndDate) => {
    //Show frontend dates
    setValue(_buildFrontendDates(newStartDate, newEndDate));
    //Set url dates
    form.change('dates', _buildUrlDates(newStartDate, newEndDate));
    //Close date range selection popup
    setShowPopup(false);
  };

  const onClear = () => {
    setValue('');
    form.change('dates', undefined);
    setShowPopup(false);
  };

  return (
    <div className={datesSearchWrapperClasses}>
      {!showPopup && (<div className={iconClass}>
        <IconCalendar />
      </div>)}
      <Field
        name="dates"
        render={({ input, meta }) => {
          return (
            <input
              id="input-search-dates"
              className={isMobile ? css.mobileInput : css.desktopInput}
              type="text"
              placeholder={intl.formatMessage({
                id: 'TopbarSearchForm.placeholder.dates',
              })}
              value={value || _buildFrontendDatesFromString(input.value)}
              ref={inputRef}
              onClick={() => setShowPopup(true)}
              readOnly // make input read-only to prevent typing directly
            />

          );
        }} />
      {showPopup && (
        <OutsideClickHandler onOutsideClick={() => setShowPopup(false)}>
          <FieldDateRangeController
            name="dates"
            minimumNights={0}
            onChange={({ startDate, endDate }) => {
              handleChange(startDate, endDate);
            }}
          />
          <div className={css.buttonsWrapper}>
            <button className={css.clearButton} type="button" onClick={() => onClear()}>
              {intl.formatMessage({ id: 'FilterForm.clear' })}
            </button>
            <button className={css.cancelButton} type="button" onClick={() => setShowPopup(false)}>
              {intl.formatMessage({ id: 'FilterForm.cancel' })}
            </button>
            <button className={css.submitButton} type="button" onClick={() => setShowPopup(false)}>
              {intl.formatMessage({ id: 'FilterForm.submit' })}
            </button>
          </div>
        </OutsideClickHandler>
      )
      }
    </div>
  );
};

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    // onChange is used for location search
    // this.onChange = this.onChange.bind(this);
    // onSubmit is used for keywords and dates search
    this.onSubmit = this.onSubmit.bind(this);

    // Callback ref
    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.setSearchInput = element;
    };
  }

  // onChange(location) {
  //   const { appConfig, onSubmit } = this.props;
  //   if (!isMainSearchTypeKeywords(appConfig) && location.selectedPlace) {
  //     // Note that we use `onSubmit` instead of the conventional
  //     // `handleSubmit` prop for submitting. We want to autosubmit
  //     // when a place is selected, and don't require any extra
  //     // validations for the form.
  //     onSubmit({ location });
  //     // blur search input to hide software keyboard
  //     this.searchInput?.blur();
  //   }
  // }

  onSubmit(values) {
    const { onSubmit } = this.props;
    const { keywords, location, dates } = values || {};

    onSubmit({ keywords, location, dates });
    // blur search input to hide software keyboard
    this.searchInput?.blur();
  }

  render() {
    const { appConfig, ...restOfProps } = this.props;

    return (
      <FinalForm
        {...restOfProps}
        onSubmit={this.onSubmit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            handleSubmit,
            form,
            showLabel,
            rootButtonClass,
          } = formRenderProps;
          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          const keywordSearchWrapperClasses = classNames(
            css.keywordSearchWrapper,
            isMobile ? css.mobileInputRoot : desktopInputRootClass
          );

          const datesSearchWrapperClasses = classNames(
            css.datesSearchWrapper,
            isMobile ? css.mobileInputRoot : desktopInputRootClass
          );

          return (
            <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="SearchPage">

              <KeywordSearchField
                keywordSearchWrapperClasses={keywordSearchWrapperClasses}
                iconClass={classNames(isMobile ? css.mobileIcon : css.desktopIcon || css.icon)}
                intl={intl}
                isMobile={isMobile}
                inputRef={this.setSearchInputRef}
              />

              <LocationSearchField
                desktopInputRootClass={desktopInputRootClass}
                intl={intl}
                isMobile={isMobile}
                inputRef={this.setSearchInputRef}
                onLocationChange={() => this.onSubmit(form.getState().values)}
              />

              <DateRangeSearchField
                datesSearchWrapperClasses={datesSearchWrapperClasses}
                iconClass={classNames(isMobile ? css.mobileIcon : css.desktopIcon || css.icon)}
                isMobile={isMobile}
                intl={intl}
                inputRef={this.setSearchInputRef}
                form={form}
              />

              <button className={classNames(rootButtonClass || (isMobile ? css.mobileSearchButton : css.searchButton))}>
                <div className={css.searchIcon}>
                  <IconSearchDesktop />
                </div>
                {showLabel || isMobile ? <FormattedMessage id='TopbarSearchForm.actionMessage' /> : null}
              </button>
            </Form>
          );
        }}
      />
    );
  }
}

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
