// AccordionItem.js
import React from 'react';
import './Accordion.css';
import { PiCaretRightBold } from 'react-icons/pi';
import { H2, H3, H5, ListingCard, NamedLink } from '../../components';

const AccordionItem = ({ title, content, isOpen, onClick, index }) => {
  return (
    <div className={`accordion ${isOpen && 'active_accordion'}`}>
      <div className={`accordion-title ${isOpen && 'active_accordion_title'}`} onClick={onClick}>
        <H2 className={'title'}>{title} </H2>
        <PiCaretRightBold className={`rotate_icon ${isOpen ? 'rotate-down' : 'rotate-right'}`} />
      </div>
      {/* {index} ---- {title} */}
      {isOpen && (
        <p dangerouslySetInnerHTML={{ __html: content }} className={'accordion-content'} />
      )}
    </div>
  );
};

export default AccordionItem;
